.App {
    max-width: 1140px;
    margin: 0 auto;
    font-family: 'Roboto', Verdana, sans-serif;
    font-size: 1.2em;
    color: #555;
}

.App-header h1, .App-spacer h1 {
    font-size: calc(16px + 4vmin);
}

.App-header-menu {
    font-size: calc(10px + 2vmin);
}

.App-header {
    background-color: #fff;
    border-bottom: solid 1px #ccc;
    display: flex;
    flex-direction: row;
    justify-content: left;
    color: #555;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 1140px;
    z-index: 100;
}

.App-content h2, .App-content p, .App-content h3  {
    padding-left: 10px;
    padding-right: 10px;
}

.App-link {
    color: #61dafb;
}

h2 {
    margin-block-start: 1em;
    margin-block-end: 1em;
}

h1 {
    font-size: 1.5em;
    margin-block-start: 0.25em;
    margin-block-end: 0.25em;
    font-family: 'Dense', Arial, Verdana, sans-serif;
}

blockquote {
    position: relative;
}

blockquote:before {
    position: absolute;
    content: open-quote;
    font-size: 4em;
    margin-left: -0.4em;
    margin-top: -0.4em;
}
blockquote:after {
    position: absolute;
    content: close-quote;
    font-size: 4em;
    bottom: 0;
    right: 0;
    margin-right: -0.3em;
    margin-bottom: -0.8em;
}
